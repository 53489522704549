<template>
  <v-layout class="mb-1">
    <v-autocomplete
      :class="[
        'default-input-style autocomplete',
        noMargins ? 'mx-0' : hasItems ? 'ml-4' : 'mx-4',
      ]"
      outlined
      :loading="loading"
      v-model="element"
      :items="dividedData"
      :search-input.sync="searchValue"
      :label="label"
      :placeholder="placeholder"
      :item-text="display"
      :item-value="displayValue"
      :return-object="returnObject"
      :no-data-text="noDataText"
      :clearable="clearable"
      :hide-details="hideDetails"
      :hide-no-data="hideNoData"
      @input="afterselection"
      :disabled="disabled"
      :rules="rules"
      :menu-props="{
        contentClass: 'zg-select-border-radius mx-2',
        auto: true,
      }"
      :no-filter="noFilter"
      append-icon="mdi-chevron-down"
      clear-icon="mdi-close"
      dense
      persistent-placeholder
    >
      <template v-if="autosave" slot="append">
        <AutosaveIcon :value="status"></AutosaveIcon>
      </template>
      <template v-slot:append-outer>
        <slot name="icon"></slot>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <v-icon v-if="item.favorite" small color="primary" class="mr-2"
              >mdi-star-outline</v-icon
            >{{ (display && display(item)) || item.text }}</v-list-item-title
          >
        </v-list-item-content>
      </template>
      <template v-if="showLoadMore" v-slot:append-item>
        <v-layout justify-center>
          <DefaultButton @click.prevent="$emit('loadMore')" block small
            >Pokaż więcej</DefaultButton
          >
        </v-layout>
      </template>
    </v-autocomplete>
    <v-layout shrink align-center :class="hasItems && 'mr-3'">
      <slot name="label-icon"></slot>
    </v-layout>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

export default {
  mixins: [ModificationMixin],
  props: {
    value: {},
    search: {},
    label: {},
    placeholder: {},
    noDataText: {},
    hideNoData: {},
    required: {},
    data: {},
    loading: {},
    fetch: {},
    display: {},
    displayValue: {},
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    clearAfterSelection: {
      type: Boolean,
      default: true,
    },
    noFilter: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    noMargins: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
  },
  computed: {
    element: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    searchValue: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("update:search", value);
      },
    },
    dividedData() {
      let newData = [];
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index];
        newData.push(element);
        if (index !== this.data.length - 1) {
          newData.push({ divider: true });
        }
      }
      return newData;
    },
    hasItems() {
      return !!this.$slots["label-icon"];
    },
  },
  methods: {
    afterselection() {
      if (!this.clearAfterSelection) {
        return;
      }
      this.$nextTick(() => {
        this.element = undefined;
      });
    },
  },
  mounted() {
    if (this.autosave) {
      this.startWatching("element");
    }
  },
};
</script>

<style scoped>
::v-deep .v-divider {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
</style>