import ApiService from './api.service'

const MedicalcodesService = {
    async getCodes(options, model) {
        return await ApiService.commonGETRequest(
            `/medicalcodes/${model}/`,
            JSON.parse(JSON.stringify(options))
        );
    },
    async getCategories(options, model) {
        return await ApiService.commonGETRequest(
            `/medicalcodes/${model}/categories/`,
            JSON.parse(JSON.stringify(options))
        );
    },
    async getChildren(options, model, code) {
        return await ApiService.commonGETRequest(
            `/medicalcodes/${model}/${code}/`,
            JSON.parse(JSON.stringify(options))
        );
    },
    async getFavorites(options, model) {
        return await ApiService.commonGETRequest(
            `/medicalcodes/${model}/favorites/`,
            JSON.parse(JSON.stringify(options))
        );
    },
    async updateFavorites(model, codes) {
        return await ApiService.commonPOSTRequest(
            `/medicalcodes/${model}/favorites/`,
            {},
            codes
        );
    }
}

export default MedicalcodesService