var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"mb-1"},[_c('v-autocomplete',{class:[
      'default-input-style autocomplete',
      _vm.noMargins ? 'mx-0' : _vm.hasItems ? 'ml-4' : 'mx-4' ],attrs:{"outlined":"","loading":_vm.loading,"items":_vm.dividedData,"search-input":_vm.searchValue,"label":_vm.label,"placeholder":_vm.placeholder,"item-text":_vm.display,"item-value":_vm.displayValue,"return-object":_vm.returnObject,"no-data-text":_vm.noDataText,"clearable":_vm.clearable,"hide-details":_vm.hideDetails,"hide-no-data":_vm.hideNoData,"disabled":_vm.disabled,"rules":_vm.rules,"menu-props":{
      contentClass: 'zg-select-border-radius mx-2',
      auto: true,
    },"no-filter":_vm.noFilter,"append-icon":"mdi-chevron-down","clear-icon":"mdi-close","dense":"","persistent-placeholder":""},on:{"update:searchInput":function($event){_vm.searchValue=$event},"update:search-input":function($event){_vm.searchValue=$event},"input":_vm.afterselection},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_vm._t("icon")]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[(item.favorite)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-star-outline")]):_vm._e(),_vm._v(_vm._s((_vm.display && _vm.display(item)) || item.text))],1)],1)]}},(_vm.showLoadMore)?{key:"append-item",fn:function(){return [_c('v-layout',{attrs:{"justify-center":""}},[_c('DefaultButton',{attrs:{"block":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('loadMore')}}},[_vm._v("Pokaż więcej")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.element),callback:function ($$v) {_vm.element=$$v},expression:"element"}},[(_vm.autosave)?_c('template',{slot:"append"},[_c('AutosaveIcon',{attrs:{"value":_vm.status}})],1):_vm._e()],2),_c('v-layout',{class:_vm.hasItems && 'mr-3',attrs:{"shrink":"","align-center":""}},[_vm._t("label-icon")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }