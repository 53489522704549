<template>
  <div>
    <v-layout column>
      <Autocomplete
        v-if="!disabled"
        :value.sync="code"
        :search.sync="search"
        :data="codes"
        :display="display"
        :label="label"
        placeholder="Wybierz i dodaj..."
        :noDataText="noDataText"
        :loading="loading"
        :hideNoData="hideNoData"
        :showLoadMore="totalPages > pagination.page"
        @loadMore="loadMore"
        :disabled="disabled"
        class="mb-2 zg-html-text-area"
        noFilter
        noMargins
      >
        <template slot="label-icon"> </template>
        <template slot="icon">
          <v-layout shrink align-center>
            <DefaultButton
              class="my-0 ml-0 mr-0"
              small
              icon
              v-if="favorites"
              bgColor="transparent"
              color="icons"
              @click.prevent="handleClick"
            >
              <v-icon color="icons">mdi-plus-box</v-icon>
            </DefaultButton>
            <AutosaveIcon :value="autosaveStatus"></AutosaveIcon>
          </v-layout>
        </template>
      </Autocomplete>
      <template v-for="(selectedCode, index) in displayCodes">
        <v-layout :key="index" class="my-1">
          <DefaultButton
            v-if="!disabled"
            @click.stop="() => onDeleteSelected(selectedCode)"
            icon
            bgColor="transparent"
            color="error"
          >
            <v-icon small>mdi-trash-can-outline</v-icon>
          </DefaultButton>
          <v-layout
            column
            justify-center
            :class="[
              altStyle
                ? 'zg-medicalcode-delegate-alt'
                : 'zg-medicalcode-delegate',
              disabled ? 'xs12' : 'xs8',
            ]"
          >
            <div class="ma-2 text-body-1 black--text">
              <b>{{ selectedCode.code }}</b>
              {{ ` - ${selectedCode.text}.` }}
            </div>
          </v-layout>
        </v-layout>
      </template>
    </v-layout>
    <FavoriteMedicalCodesPopup
      v-if="favoritePopupVisible"
      :value.sync="favoritePopupVisible"
      :model="model"
      @success="handleFavSuccess"
      @error="handleError"
    >
    </FavoriteMedicalCodesPopup>
  </div>
</template>

<script>
import Autocomplete from "@/components/Autocomplete";
import MedicalcodesService from "@/services/medicalcodes.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import _ from "lodash";

export default {
  props: {
    value: {
      default: () => [],
    },
    label: {
      default: "",
    },
    model: {
      default: "icd10",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    favorites: {
      type: Boolean,
      default: false,
    },
    altStyle: {
      type: Boolean,
      default: false,
    },
    autosaveStatus: {
      type: Number,
      default: 0, //Not modified
    }
  },
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      code: undefined,
      codes: [],
      selectedCodes: this.value,
      totalItems: undefined,
      search: null,
      pagination: {
        rowsPerPage: 100,
        page: 1,
        min: 1,
      },
      favoritePopupVisible: false,
    };
  },
  watch: {
    search() {
      this.resetPagination(this.search);
      this.fetch();
    },
    code() {
      if (!this.code) {
        return;
      }
      this.selectedCodes.push(this.code);
      this.selectedCodes = _.uniqBy(this.selectedCodes, "id");
      this.externalCodes = this.selectedCodes;
    },
    value() {
      this.selectedCodes = this.value;
    },
  },
  computed: {
    displayCodes() {
      return this.disabled ? this.value : this.selectedCodes;
    },
    externalCodes: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    totalPages() {
      if (this.totalItems == null || this.pagination.rowsPerPage == null)
        return 1;

      let tmp = Math.ceil(this.totalItems / this.pagination.rowsPerPage);
      if (tmp <= 0) return 1;
      return tmp;
    },
    hideNoData() {
      if (this.message != "" || this.totalItems !== undefined) return false;

      return true;
    },
    noDataText() {
      if (this.message != "") return this.message;
      else return "Brak kodów";
    },
  },
  components: {
    Autocomplete,
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    AutosaveIcon: () => import("../AutosaveIcon.vue"),
    FavoriteMedicalCodesPopup: () =>
      import("@/components/popups/FavoriteMedicalCodesPopup"),
  },
  methods: {
    handleFavSuccess(text) {
      this.fetch(true);
      this.handleSuccess(text);
    },
    handleClick() {
      this.favoritePopupVisible = true;
    },
    display(item) {
      return `${item.code} - ${item.text}`;
    },
    displayValue(item) {
      return item.id;
    },
    onDeleteSelected(code) {
      const foundIndex = _.findIndex(this.selectedCodes, { id: code.id });
      if (foundIndex !== -1) {
        this.selectedCodes.splice(foundIndex, 1);
      }
      this.externalCodes = this.selectedCodes;
    },
    resetPagination(search) {
      this.pagination.search = search;
      this.pagination.page = 1;
    },
    fetch(prefetch) {
      if (
        prefetch === undefined &&
        this.selectedCodes.length > 0 &&
        this.pagination.search == this.display(this.selectedCodes.slice(-1)[0])
      ) {
        return;
      }
      this.beforeRequest();
      MedicalcodesService.getCodes(this.pagination, this.model)
        .then((x) => {
          this.codes = x.results;
          this.totalItems = x.count;
          this.reset();
        })
        .catch((x) => this.handleError(x));
    },
    loadMore() {
      if (this.pagination.page + 1 > this.totalPages) return;
      this.pagination.page++;
      this.beforeRequest();
      MedicalcodesService.getCodes(this.pagination, this.model)
        .then((x) => {
          this.codes.push(...x.results);
          this.reset();
        })
        .catch((x) => this.handleError(x));
    },
  },
  created() {
    if (!this.disabled) {
      this.fetch(true);
    }
  },
};
</script>

<style scoped>
.zg-medicalcode-delegate {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
.zg-medicalcode-delegate-alt {
  background-color: #f7f7f7;
  border-radius: 15px;
}
</style>